.site-footer {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: color-bg(footer);
    z-index: -3;
  }
}

.site-footer-inner {
  padding: $footer--padding__mobile 0;
}

.footer-top {
  padding-bottom: calc($footer-inner--padding-v / 2);
}

.footer-bottom {
  padding-top: calc($footer-inner--padding-v / 2);
}

.footer-blocks {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc($footer-block--padding_h / -2);
  margin-left: calc($footer-block--padding_h / -2);
  margin-top: calc($footer-block--padding_v / -2);

  &:last-of-type {
    margin-bottom: calc($footer-block--padding_v / -2) + $footer-blocks--padding;
  }

  &:not(:last-of-type) {
    margin-bottom: calc($footer-block--padding_v / 2);
  }
}

.footer-block {
  flex-grow: 1;
  flex-basis: $footer-block--min-width;
  box-sizing: content-box;
  padding: calc($footer-block--padding_v / 2) calc($footer-block--padding_h / 2);

  * {
    box-sizing: border-box;
  }

  ul li {
    &:not(:last-child) {
      margin-bottom: $footer-block-list-padding;
    }
  }

  a {
    @include anchor-aspect(footer-block);
    @include font-weight(footer-block-link);
    text-transform: $link-footer-block--transform;

    .invert-color & {
      @include anchor-aspect(footer-block, inverse);
    }
  }
}

.footer-block-title {
  @include font-size(block-title);
  @include font-weight(block-title);
  color: color(block-title);
  text-transform: $footer-block-title--transform;
  margin-bottom: $footer-block-title-padding;

  .invert-color & {
    color: color(block-title-inverse);
  }
}

@include media('<=medium') {
  .footer-top,
  .footer-bottom {
    > *:not(:last-child) {
      margin-bottom: $footer-inner--padding-v;
    }
  }
}

@include media('>medium') {
  @if ($footer--padding__mobile != $footer--padding__desktop) {
    .site-footer-inner {
      padding: $footer--padding__desktop 0;
    }
  }

  .footer-top,
  .footer-bottom {
    &.space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .footer-top,
  .footer-bottom {
    &.invert-order-desktop {
      > *:first-child {
        order: 1;
      }
    }
  }
}
